@keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}
.loader-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.3s linear;
}
.loader-outer.hide {
  visibility: hidden;
  opacity: 0;
}
.loader-outer .loader-container {
  height: 100%;
  width: 100%;
  display: table;
}
.loader-outer .loader-container .loader-inner {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}
.loader-outer .loader-container .loader-inner .loader {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: rgba(0, 0, 0, 0.65);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-left-color: rgba(0, 0, 0, 0.65);
  border-right-color: rgba(0, 0, 0, 0.15);
  animation: preloader 0.8s linear infinite;
}
.inline-loader {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  padding: 0;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: rgba(0, 0, 0, 0.65);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-left-color: rgba(0, 0, 0, 0.65);
  border-right-color: rgba(0, 0, 0, 0.15);
  animation: preloader 0.8s linear infinite;
}

@keyframes preloader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888888;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/*Scroll bar nav*/
.default-scroll::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}